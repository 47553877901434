


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Cropper } from 'vue-advanced-cropper';
import i18n from '@/i18n';
import ImageCroppie from '@/enums/ImageCroppie';

@Component({
  components: {
    Cropper,
  },
})
export default class ImageCropper extends Vue {
  @Prop()
  private imgSrc?: string;
  @Prop()
  private stencilprops?: any;
  @Prop()
  private TypeofImage?: string;
  @Prop()
  private width?: number;
  @Prop()
  private height?: number;
  @Prop()
  private moduleType?: string;
  @Prop()
  private mediaPrefix!: string;
  @Prop() private IsFullImage?: boolean;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private img: string | undefined = require('@/assets/Images/ImageMissing.png');
  private showImageScaleandCrop = false;
  private imgWidthSelected = 400;
  private widthDimensions = 150;
  private heightDimensions = 150;
  private localModuleType= '';
  private localIsFullImage = false;
  public get imageCroppie(): typeof ImageCroppie {
    return ImageCroppie;
  }

  private mounted() {
    this.localIsFullImage = this.IsFullImage !== undefined ? this.IsFullImage : false;
    // set width and height only for advertisement
    if (this.width !== null && this.width !== undefined && this.height !== null && this.height !== undefined ) {
      this.widthDimensions = this.width;
      this.heightDimensions = this.height;
    }
    this.$root.$on('showcroopedimage',(val: boolean)=>  {
      this.showImageScaleandCrop = val;
    });
    if(this.moduleType) {
      this.localModuleType =  this.moduleType;
    }
    if(this.localIsFullImage && this.TypeofImage === ImageCroppie.IconImage) {
      this.showImageScaleandCrop = true;
    }
  }
  private coordinates: any = {
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  };
  private GetTitle(): string {
    if(this.localModuleType === ImageCroppie.Notification) {
      return i18n.t('Notification.ImageProcess.AdjustThumbnail').toString();
    } else {
      return i18n.t('ImageCroppie.ScaleOrCrop').toString();
    }
  }
  private defaultPosition() {
    return {
      left: 0,
      top: 0,
    };
  }
  private defaultSize() {
    const imageSelectedSize = (this.$refs.imgcropper as any).imageSize;
    const imgType = this.TypeofImage;
    if (imgType === ImageCroppie.IconImage && !this.localIsFullImage) {
      return {
        width: this.imgWidthSelected,
        height: imageSelectedSize.height < 400 ? 400 : imageSelectedSize.height,
      };
    } else {
      return {
        width: imageSelectedSize.width,
        height: imageSelectedSize.height < 400 ? 400 : imageSelectedSize.height,
      };
    }
  }

  private ShowDivImageScaleandCrop() {
    if (this.showImageScaleandCrop) {
      this.$emit(
        'CroppedImageIconUrl',
        '',
        this.imgSrc,
      );
    }
    this.showImageScaleandCrop = !this.showImageScaleandCrop;
  }
  private GetImgeUrl() {
    this.img = this.imgSrc;
    return this.img;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private change({ coordinates, canvas }: { coordinates: any; canvas: any }) {
    if(this.localModuleType === ImageCroppie.Notification) {
      this.showImageScaleandCrop = true;
    }
    if (!this.showImageScaleandCrop) {
      return;
    }
    if (typeof this.img !== 'undefined') {
      if (
        !this.img.includes('/img/loading') &&
        !this.img.includes('data:image/png')
      ) {
        const imgType = this.TypeofImage;
        if (imgType === ImageCroppie.IconImage) {
          this.$emit(
            'CroppedImageIconUrl',
            this.loadingImage,
            this.loadingImage,
          );
        } else {
          // this.$emit(
          //   "CroppedFeaturedImageIconUrl",
          //   this.loadingImage,
          //   this.loadingImage
          // );
        }
        if(imgType === ImageCroppie.IconImage && this.localIsFullImage) {
          this.widthDimensions = coordinates.width;
          this.heightDimensions = coordinates.height;
        }
        this.UploadFileImage(
          this.img,
          String(coordinates.top),
          String(coordinates.left),
          String(coordinates.width),
          String(coordinates.height),
        );
      } else {
        this.img = require('@/assets/Images/ImageMissing.png');
      }
    }
  }
  private UploadFileImage(
    url: string | undefined = '',
    topPosition: string,
    leftPosition: string,
    w: string,
    h: string,
  ) {
    const objForJson = {
      fullUri: url,
      x: leftPosition,
      y: topPosition,
      w,
      h,
      tbw: this.widthDimensions,
      tbh: this.heightDimensions,
    };
    this.$axios
      .post('/FileUpload/GenerateImageThumbnailJson', {
        jsonData : JSON.stringify(objForJson),
        mediaPrefix: this.mediaPrefix,
      })
      .then((response) => {
        const imgType = this.TypeofImage;
        if (imgType === ImageCroppie.IconImage) {
          // this.$parent.$emit(
          //   'SetImageIconUrl',
          //   response.data.thumbUri,
          //   response.data.fullUri,
          // );
          this.$emit(
            'CroppedImageIconUrl',
            response.data.thumbUri,
            response.data.fullUri,
          );
        } else {
          // Do nothing
          this.$parent.$emit(
            'SetFeaturedImageIconUrl',
            response.data.thumbUri,
            response.data.fullUri,
          );
          this.$emit(
            'CroppedFeaturedImageIconUrl',
            response.data.thumbUri,
            response.data.fullUri,
          );
        }
      })
      .catch(() => {
        // Log error if required
      });
  }
  @Watch('IsFullImage')
  private CheckChangeInIsFullImage(val: boolean) {
    this.localIsFullImage = val;
    if(!this.localIsFullImage) {
      this.showImageScaleandCrop = false;
    } else {
      this.showImageScaleandCrop = true;
    }
  }
}
